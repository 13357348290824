import React, { useState } from 'react';
import { Box, ListItem, ListItemIcon, ListItemText, Collapse, List, withStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { logoutUser } from '../../redux/user/user.actions';
import { styles } from '../../theme/styles';
import { authService } from '../../services/authentication';

function Menu(props) {
  const [openProfile, setOpenProfile] = useState(false);
  const [openContract, setOpenContract] = useState(false);

  const logout = () => {
    authService.logout();
    props.history.push('/login');
    props.logoutUser();
  };
  const { t, i18n } = useTranslation();

  const isContracting = props.history.location.pathname.split('/')[1].slice(0, 8) === 'contract';

  const url = process.env.REACT_APP_MULTISPORT_URL || 'https://tesztcontract.multisportpass.hu';
  const intranet = `${process.env.REACT_APP_INTRA_URL || 'https://intranet2.mbsm.hu'}${i18n.language === 'en' ? '/en' : ''}/intranet`;

  // 2022-01-06 pjung TNRUKAYCM-2342 FLEXI hónap link
  const { classes, disabled, currentUser: almafa } = props;
  const user = authService.currentUser();
  const urlink = `${intranet}/flexi/${almafa.kr}/${almafa.tax}/${user.firstName} ${user.lastName}/${user.email}`;
  const url2link = `${url}${i18n.language === 'en' ? '/en' : ''}/multisport/creditvasarlas/${almafa.kr}/${almafa.cardNumber}`;
  const ma = new Date().toISOString().substring(0, 10);
  // 2022-05-10 pjung TNRUKAYCM-1395 FLEXI menüpont "Szü" státusz esetén is...
  const kelleLink = almafa.kr && almafa.tax && ma >= almafa.from && ma <= almafa.to && (almafa.cardStatus === '1' || almafa.cardStatus === '9');
  const joker = ma <= '2025-01-15' && `${intranet}/tars-hosszabbitas`;
  // 2023-11-08 pjung TNRUKAYCM-1711 Társ Meghívás / -Upgrade
  const kelleTars = !!almafa.tars;
  const calcTarsUrl = (kr, tax) => {
    if (!(kr && tax)) return '';
    const r1 = Math.floor(Math.random() * 89) + 11;
    const r2 = Math.floor(Math.random() * 6) + 4;
    console.log('KR', kr, 'adó', tax, 'R1', r1, 'R2', r2);
    const s1 = kr.split('').reduce((sum, value) => (sum += value - 0), 0);
    const s2 = tax.split('').reduce((sum, value) => (sum += value - 0), 0);
    const sum = (s1 + s2 + r1) % r2;
    return (kr?.length === 5 ? '0' : '') + kr + tax + r1 + r2 + sum;
  };
  const tarsLink = `${t(almafa.tarsEmail ? 'tars.upgrade' : 'tars.invite')}${
    // eslint-disable-next-line eqeqeq
    almafa.ev == ma.substring(0, 4) ? '' : ' (' + almafa.ev + ')'
  }`;
  const tarsUrl = `${intranet}/tarskartyas-szerzodeskotes/${calcTarsUrl(almafa.kr, almafa.tax)}/${user.email}`;

  return (
    <>
      <Box style={{ height: '520px', position: 'absolute', width: '280px' }} display={{ xs: 'none', md: 'block' }}>
        <Box
          style={{
            position: 'absolute',
            left: '20px',
            right: '0',
            top: '0',
            bottom: '90px',
            overflowY: 'auto'
          }}>
          <ListItem
            disableGutters
            button
            dense
            className={classes.withoutIconMenuItem}
            style={props.history.location.pathname === '/payment' ? { color: 'rgba(49, 139, 129, 1)' } : { color: 'black' }}
            onClick={() => {
              props.history.push('/payment');
            }}>
            <ListItemText primary={t('menu.payment')} />
          </ListItem>

          <ListItem
            button
            style={{ padding: '0 16px' }}
            onClick={() => {
              setOpenProfile(!openProfile);
            }}>
            <ListItemIcon>{openProfile ? <ExpandMore /> : <ChevronRight />}</ListItemIcon>
            <ListItemText primary={t('menu.profil')} className={classes.expandMenuItem} />
          </ListItem>
          <Collapse in={openProfile} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ paddingLeft: '1em' }}>
              <ListItem
                button
                dense
                className={classes.withoutIconMenuItem}
                onClick={() => {
                  props.history.push('/profile/data');
                }}
                style={props.history.location.pathname === '/profile/data' ? { color: 'rgba(49, 139, 129, 1)' } : { color: 'black' }}>
                <ListItemText primary={t('menu.profil.data')} />
              </ListItem>
              <ListItem
                button
                dense
                className={classes.withoutIconMenuItem}
                style={props.history.location.pathname === '/profile/chpass' ? { color: 'rgba(49, 139, 129, 1)' } : { color: 'black' }}
                onClick={() => {
                  props.history.push('/profile/chpass');
                }}>
                <ListItemText primary={t('menu.profil.chpass')} />
              </ListItem>
            </List>
          </Collapse>

          {/* 2021-03-22 pjung TNRUKAYCM-1039 AYCM almenü
            2021-11-26 pjung TNRUKAYCM-1278 AYCM -> AYCM SportPass */}
          <ListItem button style={{ padding: '0 16px' }}>
            <ListItemText
              primary="AYCM SportPass"
              className={classes.expandMenuItem}
              style={{ paddingLeft: '2em', color: 'rgba(49, 139, 129, 1)' }}
            />
          </ListItem>
          <List component="div" disablePadding dense style={{ paddingLeft: '1em' }}>
            <ListItem
              style={
                props.history.location.pathname === '/contracting/init' || (isContracting && sessionStorage.getItem('type') === 'R')
                  ? { color: 'rgba(49, 139, 129, 1)' }
                  : { color: 'rgba(49, 139, 129, 1)' }
              }
              button
              dense
              className={classes.primaryMenuItem}
              onClick={() => {
                props.history.push('/contracting/init');
              }}>
              <ListItemText primary={t('menu.contracting')} />
            </ListItem>
            <Box display={disabled ? 'none' : 'block'}>
              <ListItem
                cr
                style={
                  props.history.location.pathname === '/contracting/upgrade' || (isContracting && sessionStorage.getItem('type') === 'U')
                    ? { color: 'rgba(49, 139, 129, 1)' }
                    : { color: 'rgba(49, 139, 129, 1)' }
                }
                button
                dense
                className={classes.primaryMenuItem}
                onClick={() => {
                  props.history.push('/contracting/upgrade');
                }}>
                <ListItemText primary={t('menu.contract.update')} />
              </ListItem>
            </Box>
            {/* 2023-11-08 pjung TNRUKAYCM-1711 */}
            <Box display={kelleTars ? 'block' : 'none'}>
              <ListItem
                cr
                style={
                  props.history.location.pathname.startsWith('/contracting/upgrade_tars/') ||
                  (isContracting && sessionStorage.getItem('type') === 'U')
                    ? { color: 'rgba(49, 139, 129, 1)' }
                    : { color: 'rgba(49, 139, 129, 1)' }
                }
                button
                dense
                className={classes.primaryMenuItem}
                onClick={() => {
                  if (almafa.tarsEmail) {
                    // Upgrade
                    props.history.push(`/contracting/upgrade_tars/${almafa.tarsKr}/${almafa.tarsEmail}`);
                  } // Meghívás
                  else window.open(tarsUrl);
                }}>
                <ListItemText primary={tarsLink} />
              </ListItem>
            </Box>
          </List>

          {/* 2021-03-23 pjung TNRUKAYCM-1039 MultiSport almenü
            2021-11-26 pjung TNRUKAYCM-1278 MultiSport -> MultiSport Pass */}
          <ListItem button style={{ padding: '0 16px' }}>
            <ListItemText
              primary="MultiSport Pass"
              className={classes.expandMenuItem}
              style={{ paddingLeft: '2em', color: 'rgba(234, 0, 138, 1)' }}
            />
          </ListItem>
          <List component="div" disablePadding dense style={{ paddingLeft: '1em' }}>
            <ListItem
              style={{ color: 'rgba(234, 0, 138, 1)' }}
              button
              dense
              className={classes.primaryMenuItem}
              onClick={() => {
                window.open(`${url}/multisport/entitlement-verification`);
              }}>
              <ListItemText primary={t('menu.contracting')} />
            </ListItem>
            <Box display={disabled ? 'none' : 'block'}>
              <ListItem
                style={
                  props.history.location.pathname === '/contracting/upgrade_multi' || (isContracting && sessionStorage.getItem('type') === 'U')
                    ? { color: 'rgb(234, 0, 138)' }
                    : { color: 'rgba(234, 0, 138, 1)' }
                }
                button
                dense
                className={classes.primaryMenuItem}
                onClick={() => {
                  props.history.push('/contracting/upgrade_multi');
                }}>
                <ListItemText primary={t('menu.contract.update')} />
              </ListItem>
            </Box>
            {/* 2022-09-19 pjung TNRUKAYCM-1430 Új menüpont */}
            <ListItem
              style={{ color: 'rgba(234, 0, 138, 1)' }}
              button
              dense
              className={classes.primaryMenuItem}
              onClick={() => {
                window.open(url2link);
              }}>
              <ListItemText primary={t('menu.extra')} />
            </ListItem>
          </List>

          <ListItem
            button
            style={{ padding: '0 16px' }}
            onClick={() => {
              setOpenContract(!openContract);
            }}>
            <ListItemIcon>{openContract ? <ExpandMore /> : <ChevronRight />}</ListItemIcon>
            <ListItemText primary={t('menu.contract.detail')} className={classes.expandMenuItem} />
          </ListItem>
          <Collapse in={openContract} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ paddingLeft: '1em' }}>
              <ListItem
                button
                dense
                className={classes.withoutIconMenuItem}
                style={props.history.location.pathname === '/invoicedata' ? { color: 'rgba(49, 139, 129, 1)' } : { color: 'black' }}
                onClick={() => {
                  props.history.push('/invoicedata');
                }}>
                <ListItemText primary={t('menu.contract.invoicedata')} />
              </ListItem>
              <ListItem
                button
                dense
                className={classes.withoutIconMenuItem}
                style={props.history.location.pathname === '/invoices' ? { color: 'rgba(49, 139, 129, 1)' } : { color: 'black' }}
                onClick={() => {
                  props.history.push('/invoices');
                }}>
                <ListItemText primary={t('menu.contract.invoices')} />
              </ListItem>
              <ListItem
                button
                dense
                className={classes.withoutIconMenuItem}
                style={props.history.location.pathname === '/documents' ? { color: 'rgba(49, 139, 129, 1)' } : { color: 'black' }}
                onClick={() => {
                  props.history.push('/documents');
                }}>
                <ListItemText primary={t('menu.contract.documents')} />
              </ListItem>
            </List>
          </Collapse>

          {
            /* 2021-07-02 pjung TNRUKAYCM-1184 Rendkívüli szerződéskötés menüpont levétele
          2021-12-08 pjung TNRUKAYCM-1300 Rendkívüli menüpont visszatétele
          2022-01-05 pjung TNRUKAYCM-1342 FLEXI hónap link */
            kelleLink && (
              <ListItem
                button
                style={{ padding: '0 16px 0 40px' }}
                onClick={() => {
                  window.open(urlink);
                }}>
                <ListItemText primary={t('menu.reactivation')} className={classes.expandMenuItem} />
              </ListItem>
            )
          }
          {
            /* 2022-11-22 pjung TNRUKAYCM-1473 Joker menüpont */
            joker && (
              <ListItem
                button
                style={{ padding: '0 16px 0 40px', color: 'red' }}
                onClick={() => {
                  window.open(joker);
                }}>
                <ListItemText primary={t('menu.joker')} className={classes.expandMenuItem} />
              </ListItem>
            )
          }
        </Box>

        <Box
          style={{
            textAlign: 'center',
            width: '100%',
            position: 'absolute',
            bottom: '40px',
            left: 'calc(145px - 50%)'
          }}>
          <Button variant="outlined" color="secondary" onClick={logout}>
            {t('menu.logout')}
          </Button>
        </Box>
      </Box>
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  logoutUser: user => dispatch(logoutUser(user))
});

export default withStyles(styles)(withRouter(connect(null, mapDispatchToProps)(Menu)));
